import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { isEqual, pick } from 'lodash';
import RoundedModalWrapper from '../../UIComponents/RoundedModalWrapper';
import FailRequestScreen from '../../UIComponents/FailRequestScreen';
import Preloader from '../../UIComponents/Preloader';
import AudioPlayer from '../../UIComponents/AudioPlayer';
import Player from '../../UIComponents/Player';
import ConfirmRoundModal from '../../UIComponents/ConfirmRoundModal';
import EntityDoNotUseOverlay from '../../Entities/Parts/EntityDoNotUseOverlay';
import AssetInfoStatic from './AssetInfoStatic';
import Assets from 'services/Assets';
import AssetInfoEditState from './AssetInfoEditState';
import AssetActions from '../AssetActions';
import { setAssetsToPreviewAction } from './AssetsDetailsPreview.redux/actions';
import { updateAssetAction } from '../Assets.redux/Actions/assetActions';
import {
  checkIfExternalUser,
  checkPermissions,
} from '../../General/Permissions/Permission';
import {
  ASSET_TYPE,
  ASSET_ATTRIBUTE_NAMES,
  IMAGE_TAG_SUPPORTED_EXTENSIONS,
  ASSET_CATEGORIES_KEYS,
} from 'constants/Asset';
import { VALIDATION } from 'constants/Validation';
import { GAME_ACCESS_TYPE } from 'constants/General';
import { GENERAL_GAME_FOLDERS_NAMES } from 'constants/Game';
import {
  makeFieldValidation,
  str,
} from 'utils/utils';

import {
  Close as CloseIcon,
  Edit as EditIcon,
  Check as CheckIcon,
  ArrowBack as ArrowBackIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
} from '@material-ui/icons';
import { ReactComponent as TiltingPointLogoWithText } from 'assets/svg/logo/logo-with-text.svg';

import './AssetsDetailsPreview.scss';
import ListItemWithCheckbox from 'components/UIComponents/ListItemWithCheckbox';

const assetFieldsThatExternalUserCanChange = [
  'name',
  'description',
  ASSET_CATEGORIES_KEYS.SUB_CATEGORY,
  ASSET_CATEGORIES_KEYS.DEVELOPER_FORMAT,
];

const assetFieldsThatCanBeChange = [
  'doNotUse',
  'approved',
  ASSET_CATEGORIES_KEYS.CATEGORY,
  ASSET_CATEGORIES_KEYS.PRODUCTION_FORMAT,
  'CATAssetType',
  ...assetFieldsThatExternalUserCanChange,
];


const AssetsDetailsPreview = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    assetsToPreview,
    assetIndex,
    isAssetsFromGameStorage,
    isAssetsFromCollection,
  } = useSelector((state) => state.assetsToPreview);

  const {
    loading: updateLoading,
   } = useSelector((state) => state.assets.assetUpdate);

  const [currentAssetIndex, setCurrentAssetIndex] = useState(assetIndex);

  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [isMediaUnavailable, setIsMediaUnavailable] = useState(true);
  const [videoProgress, setVideoProgress] = useState(null);
  const [fullAssetUrl, setFullAssetUrl] = useState('');

  const [isEditState, setIsEditState] = useState(false);
  const [initAssetInfo, setInitAssetInfo] = useState(null);
  const [assetInfo, setAssetInfo] = useState(null);
  const [assetInfoValidation, setAssetInfoValidation] = useState({
    name: null,
    descriptions: null,
  });
  const [confirmDiscardChangesModal, setConfirmDiscardChangesModal] = useState({
    open: false,
    closeModal: false,
  });
  const currentAsset = useMemo(() => assetsToPreview[currentAssetIndex], [assetsToPreview, currentAssetIndex]);
  // Permissions
  const isUserExternal = checkIfExternalUser();
  const [isUsedByUaNa, setIsUsedByUaNa] = useState(currentAsset.usedByUa === 'N/A');
  const [isUsedUaDisabled, setUsedUaDisabled] = useState(false);
  const canEditAsset = useMemo(() =>  {
    const hasPermissionToEdit = checkPermissions(currentAsset?.gameId, GAME_ACCESS_TYPE.UPLOAD);
    const isAssetFromCreativePost = currentAsset?.creativeId;
    const isAssetFromDeveloperAssetsFolder = currentAsset?.folderPath?.split('/')[0] === GENERAL_GAME_FOLDERS_NAMES.DEVELOPER_ASSETS;

    if (isUserExternal) {
      return !isAssetFromCreativePost && isAssetFromDeveloperAssetsFolder && hasPermissionToEdit;
    }

    return hasPermissionToEdit;
  }, [isUserExternal, currentAsset]);

  const switchAsset = (step) => {
    setCurrentAssetIndex((prevState) => {
      const newCurrentAssetIndex = prevState + step;
      if (newCurrentAssetIndex < 0 || newCurrentAssetIndex > assetsToPreview.length - 1) {
        return prevState;
      }

      return newCurrentAssetIndex;
    });
  };

  const handleLeftOrRightArrowKeyDown = (e) => {
    if (e.keyCode === 37) {
      switchAsset(-1);
    }

    if (e.keyCode === 39) {
      switchAsset(1);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleLeftOrRightArrowKeyDown, false);

    return () => {
      document.removeEventListener('keydown', handleLeftOrRightArrowKeyDown, false);
    };
  }, [assetsToPreview]);

  useEffect(() => {
    setIsMediaUnavailable(false);
    const newFullAssetUrl = getAssetFullUrl(currentAsset);

    if (newFullAssetUrl !== fullAssetUrl) {
      setIsMediaLoaded(false);
    }

    setFullAssetUrl(newFullAssetUrl);
  }, [currentAsset?.id]);

  useEffect(() => {
    if (isEditState) {
      const assetInfoToEdit = pick(currentAsset, assetFieldsThatCanBeChange);

      setAssetInfo(assetInfoToEdit);
      setInitAssetInfo(assetInfoToEdit);
    } else {
      setAssetInfo(null);
      setInitAssetInfo(null);
    }
  }, [currentAsset, isEditState]);

  const getAssetFullUrl = (asset) => {
    const { type, path, mediumPreviewUrl, storageUuid, extension } = asset;

    if (
      type === ASSET_TYPE.VIDEO ||
      type === ASSET_TYPE.AUDIO ||
      IMAGE_TAG_SUPPORTED_EXTENSIONS.includes(extension)
    ) {
      return `/file-store/${storageUuid}${path}`;
    }

    if (mediumPreviewUrl) {
      return mediumPreviewUrl;
    }

    return str.getExtensionIconSrc(extension);
  };

  const handleSetUsedByUa = async () => {
    setIsUsedByUaNa(!isUsedByUaNa);
    setUsedUaDisabled(true);
    await Assets.setUsedByUa(currentAsset.id, !isUsedByUaNa);
    setUsedUaDisabled(false);
  };

  const handleErrorMedia = () => {
    setIsMediaLoaded(true);
    setIsMediaUnavailable(true);
  };

  const proceedLeaveEditState = () => {
    setIsEditState(false);
    setAssetInfoValidation({
      name: null,
      descriptions: null,
    });
  };

  const proceedCloseAssetsDetailsPreview = () => {
    dispatch(setAssetsToPreviewAction([]));
    proceedLeaveEditState();
    setCurrentAssetIndex(0);
    if (history.location.pathname.includes('share-assets')) {
      history.push('/search');
    }
  };

  const handleLeaveEditState = (closeModal) => {
    if (!isEqual(assetInfo, initAssetInfo)) {
      setConfirmDiscardChangesModal({
        open: true,
        closeModal,
      });
    } else {
      if (closeModal) {
        proceedCloseAssetsDetailsPreview();
      } else {
        proceedLeaveEditState();
      }
    }
  };

  const handleCloseAssetsDetailsPreview = () => {
    if (isEditState) {
      handleLeaveEditState(true);
    } else {
      proceedCloseAssetsDetailsPreview();
    }
  };

  const handleConfirmDiscardChanges = () => {
    if (confirmDiscardChangesModal.closeModal) {
      proceedCloseAssetsDetailsPreview();
    } else {
      proceedLeaveEditState();
    }

    setConfirmDiscardChangesModal({
      open: false,
      closeModal: false,
    });
  };

  const actionAfterDelete = (currentAsset) => {
    const newAssetsToPreview = assetsToPreview.filter((assetToPreview) => assetToPreview.id !== currentAsset.id);

    if (newAssetsToPreview.length) {
      dispatch(setAssetsToPreviewAction(newAssetsToPreview, isAssetsFromGameStorage));
    } else {
      proceedCloseAssetsDetailsPreview();
    }
  };

  const handleChangeAssetName = (newAssetName) => {
    setAssetInfo({
      ...assetInfo,
      name: newAssetName,
    });
  };

  const handleBlurAssetNameInput = (newAssetName) => {
    let validation = makeFieldValidation(
      newAssetName,
      VALIDATION.ASSET_NAME,
      'Please enter a valid asset name'
    );

    if (!newAssetName.trim().length) {
      validation = 'Asset name can\'t contain only spaces';
    }

    setAssetInfoValidation({
      ...assetInfoValidation,
      name: validation,
    });

    return validation;
  };

  const handleBlurAssetDescriptionTextarea = (newDescription) => {
    let validation = null;

    if (newDescription.length > 100) {
      validation = 'Maximum number of symbols is 100';
    }

    setAssetInfoValidation({
      ...assetInfoValidation,
      description: validation,
    });

    return validation;
  };

  const handleSaveAsset = async () => {
    if (
      handleBlurAssetNameInput(assetInfo.name) ||
      handleBlurAssetDescriptionTextarea(assetInfo.description)
    ) {
      return;
    }

    let transformedAssetInfo = {
      ...assetInfo,
      CATAssetType: {
        attribute: ASSET_ATTRIBUTE_NAMES.TYPE,
        value: assetInfo.CATAssetType || null,
      },
    };

    if (isUserExternal) {
      transformedAssetInfo = pick(transformedAssetInfo, assetFieldsThatExternalUserCanChange);
    }

    await dispatch(updateAssetAction(
      currentAsset.id,
      transformedAssetInfo,
      isAssetsFromGameStorage,
      currentAsset.folderPath,
      currentAsset.storageId,
      assetInfo
    ));

    setIsEditState(false);
  };

  if (!assetsToPreview.length) {
    return;
  }

  const displayAssetPreview = (type) => {
    switch (type) {
      case ASSET_TYPE.VIDEO:
        return (
          <Player
            play
            url={fullAssetUrl}
            notApproved={!currentAsset?.approved}
            onReady={() => setIsMediaLoaded(true)}
            onVideoProgress={setVideoProgress}
            onError={handleErrorMedia}
          />
        );
      case ASSET_TYPE.AUDIO:
        return (
          <>
            <TiltingPointLogoWithText />
            <AudioPlayer
              url={fullAssetUrl}
              notApproved={!currentAsset?.approved}
              onLoad={() => setIsMediaLoaded(true)}
              onError={handleErrorMedia}
            />
          </>
        );
      default:
        return (
          <>
            <img
              className='asset-details-preview__image'
              src={fullAssetUrl}
              onLoad={() => setIsMediaLoaded(true)}
              onError={handleErrorMedia}
              alt='File asset preview'
            />
            {!currentAsset?.approved &&
              <EntityDoNotUseOverlay iconFontSize={76} />
            }
          </>
        );
    }
  };

  return (
    <RoundedModalWrapper
      isOpen={!!assetsToPreview.length}
      padding='40px 50px 32px'
      withoutScroll
      onClose={handleCloseAssetsDetailsPreview}
    >
      <div className='asset-details-preview'>
        {updateLoading || isUsedUaDisabled && <Preloader />}

        <div
          className={classnames('asset-details-preview__header', {
            'editState': isEditState && assetInfo?.name,
          })}
        >
          {isEditState && assetInfo?.name ?
            <>
              <TextareaAutosize
                className={classnames('asset-details-preview__name-textarea', {
                  'error': assetInfoValidation.name,
                })}
                onChange={(e) => handleChangeAssetName(e.target.value)}
                onBlur={(e) => handleBlurAssetNameInput(e.target.value)}
                value={assetInfo?.name}
                maxRows={3}
              />

              {assetInfoValidation.name &&
                <p className='asset-details-preview__name-error'>
                  {assetInfoValidation.name}
                </p>
              }
            </> :
            <p className='asset-details-preview__name'>{currentAsset?.name}</p>
          }
        </div>

        <div className='asset-details-preview__body'>
          <div
            className={classnames('asset-details-preview__media', {
              'audio': currentAsset?.type === ASSET_TYPE.AUDIO,
            })}
          >
            {!isMediaLoaded &&
              <div className='asset-details-preview__media-status-container'>
                <Preloader />
              </div>
            }

            {isMediaUnavailable &&
              <div className='asset-details-preview__media-status-container'>
                <FailRequestScreen message={'Media is unavailable'} />
              </div>
            }

            {displayAssetPreview(currentAsset?.type)}
          </div>
          <div>
            <div className='asset-details-preview__info'>
              <h3 className='asset-details-preview__info-title'>Asset info</h3>
              <div className='asset-details-preview__info-body'>
                {isEditState ?
                  <AssetInfoEditState
                    assetInfo={assetInfo}
                    setAssetInfo={setAssetInfo}
                    assetInfoValidation={assetInfoValidation}
                    handleBlurAssetDescriptionTextarea={handleBlurAssetDescriptionTextarea}
                    isUserExternal={isUserExternal}
                  /> :
                  <AssetInfoStatic asset={currentAsset} />
                }
              </div>
            </div>
            {currentAsset.usedByUa !== true && <ListItemWithCheckbox
              item='"Used by UA" is N/A'
              checkedCondition={isUsedByUaNa}
              disabledCondition={isUsedUaDisabled}
              handleCheck={handleSetUsedByUa}
            />}
          </div>
        </div>
        <div
          className={classnames('asset-details-preview__footer', {
            'hidden': isAssetsFromCollection,
          })}
        >
          {currentAsset && !isAssetsFromCollection &&
            <AssetActions
              isDetailsModal
              isNeedToUpdateElastic={!isAssetsFromGameStorage}
              selectedAssets={[currentAsset]}
              selectedAssetIdsSet={new Set([currentAsset?.id])}
              videoProgress={videoProgress}
              actionAfterDelete={() => actionAfterDelete(currentAsset)}
            />
          }

          <div className='asset-details-preview__buttons-container'>
            {isEditState ?
              <>
                <button
                  className='asset-details-preview__button inverse'
                  onClick={() => handleLeaveEditState()}
                >
                  <CloseIcon className='icon-inside-button' />
                  <span>Cancel</span>
                </button>
                <button
                  className='asset-details-preview__button'
                  onClick={handleSaveAsset}
                >
                  <CheckIcon className='icon-inside-button' />
                  <span>Save</span>
                </button>
              </> :
              <button
                className={classnames('asset-details-preview__button', {
                  'hidden': !canEditAsset || isAssetsFromCollection,
                })}
                onClick={() => setIsEditState(true)}
              >
                <EditIcon className='icon-inside-button' />
                <span>Edit</span>
              </button>
            }
          </div>
        </div>

        {assetsToPreview.length > 1 &&
          <>
            <button
              className='asset-details-preview__switch-asset-button prev'
              disabled={currentAssetIndex === 0}
              onClick={() => switchAsset(-1)}
            >
              <NavigateBeforeIcon className='asset-details-preview__switch-asset-icon' />
            </button>

            <button
              className='asset-details-preview__switch-asset-button next'
              disabled={currentAssetIndex === assetsToPreview?.length - 1}
              onClick={() => switchAsset(1)}
            >
              <NavigateNextIcon className='asset-details-preview__switch-asset-icon' />
            </button>
          </>
        }
      </div>

      <ConfirmRoundModal
        isOpen={confirmDiscardChangesModal.open}
        title='Cancel editing'
        text='Are you sure you want to discard the changes you made?'
        agreeText='discard'
        cancelText='back'
        cancelIcon={<ArrowBackIcon className='icon-inside-button' />}
        onAgree={handleConfirmDiscardChanges}
        onClose={() => {
          setConfirmDiscardChangesModal({
            open: false,
            closeModal: false,
          });
        }}
      />
    </RoundedModalWrapper>
  );
};

export default AssetsDetailsPreview;
