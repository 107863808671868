import filesize from 'filesize';
import moment from 'moment';
import { FORMAT_DATE_WITHOUT_TIME } from 'constants/General';

/**
 * @param {object} data
 */
class AssetModel {
  constructor(data) {
    this.id = Number(data.id);
    this.entityId = Number(data.entityId);
    this.name = data.asset_name?.substring(0, data.asset_name?.lastIndexOf('.')) || data.asset_name;
    this.gameName = data.asset_game_name;
    this.gameId = Number(data.game_id);
    this.creativeId = data.creative_id;
    this.jiraProjectId = data.jira_project_id;
    this.jiraIssueKey = data.asset_issue_key;
    this.jiraTicketLink = data.pixel_url;
    this.jiraTicketName = data.asset_creative_title ?? ' ';

    this.path = data.path;
    this.folderPath = data.path?.split('/').slice(1, -1).join('/');
    this.rootFolder = data.path?.split('/').slice(1, -1)[0] ?? '';
    this.description = data.description;
    this.isFolder = data.is_folder?.toLowerCase() === 'true';
    this.type = data.asset_type;
    this.extension = data.file_extension;
    this.tabs_name = data.tabs_name;

    this.size = data.size;
    this.height = data.height;
    this.width = data.width;
    this.orientation = data.orientation;
    this.smallPreviewUrl = data.small_preview_url;
    this.mediumPreviewUrl = data.medium_preview_url;
    this.duration = data.duration;
    this.weight = data.weight ? filesize(data.weight) : 'no data';

    this.createdAt = data.created_at ? moment(data.created_at).format(FORMAT_DATE_WITHOUT_TIME) : 'no date';
    this.updatedAt = data.updated_at ? moment(data.updated_at).format(FORMAT_DATE_WITHOUT_TIME) : 'no data';

    this.category = data.category;
    this.subCategory = data.sub_category;
    this.productionFormat = data.production_format;
    this.developerFormat = data.developer_format;

    this.pod = data.pod;
    this.studio = data.studio;
    this.level = data.level;
    this.genre = data.genre;
    this.CATAssetType = data.attribute_type;
    this.tags = data.attribute_feature;

    this.approved = data.approved?.toLowerCase() === 'true';
    this.live = data.live?.toLowerCase() === 'true';
    this.doNotUse = data.do_not_use?.toLowerCase() === 'true';
    this.sourceFile = data.source_file?.toLowerCase() === 'true';
    this.usedByUa = data.used_by_ua !== 'N/A' ? data.used_by_ua?.toLowerCase() === 'true' : data.used_by_ua;
    this.productionTier= data.jira_ua_type?.raw;
    this.successRate = data.success_rate;

    this.storageId = Number(data.storage_id);
    this.gameStorageId = Number(data.game_storage_id);
    this.storageUuid = data.storage_uuid;

    this.searchCategory = data.search_category;
  }
}

export default AssetModel;
